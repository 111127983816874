import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';

const CookiesForm = () => {

    let [isCookieSet, setCookie] = useState(Cookies.get("cookieConsent"));

    const setCookieConsent = (answer) => {
        Cookies.set('cookieConsent', answer);
        setCookie(Cookies.get("cookieConsent"));
    }

  return (
    <div className={isCookieSet ? 'remove-form z-0' : `w-100 bg-gradient-skin pt-5 pb-0 px-3 position-fixed bottom-0 d-flex flex-column flex-xxl-row justify-content-evenly justify-content-xxl-around align-items-center cookies-form`} style={{zIndex: '1000'}}>
        <div className='col-11 col-md-9 col-xxl-6'>
            <p className='font-inter fs-6'>Tlačítkem „Přijmout všechny soubory cookie“, poskytnete souhlas k ukládání cookie na vašem zařízení, což pomáhá s navigací na stránce, s analýzou využití dat a marketingem dle <a href='/privacy-and-policy/'>Zásady práce s vašimi údaji</a></p>
        </div>
        <div className='col col-md-10 col-lg-9 col-xxl-6 d-flex flex-column flex-md-row justify-content-evenly align-items-center'>
            <a className='font-anton text-dark fs-5 bg-color-skin py-2 px-4 rounded-4' onClick={(e) => setCookieConsent("accept")}>Přijmout všechny soubory cookie</a>
            <a className='font-anton text-white fs-5 bg-dark py-2 px-4 rounded-4' onClick={(e) => setCookieConsent("decline")}>Přijmout pouze nezbytné cookie</a>
        </div>
    </div>
  )
}

export default CookiesForm