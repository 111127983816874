import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import '../static/css/homePage.css';

import { ReactComponent as NextPageIcon } from '../assets/link-arrow-diagonal.svg'

import Footer from '../components/Footer';

const HomePage = () => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(TextPlugin);

  let [recentProject, setRecentProject] = useState([]);
  let [favoritePosts, setFavoritePosts] = useState([]);
  let words = ["Zkrášlujeme svět", "Vytváříme architekturu", "Architektura vytváří nás", "S námi mizí problémy", "Objevují se řešení", "Jdeme do toho se srdíčkem", "Jdeme do toho celou duší", "Světlo je pro nás prožitek", "Světlo je pro vás atmosférou"];

  useEffect(() => {
    axios.get('/api/recent-project/').then((response) => {
      setRecentProject(response.data);
    });

    axios.get('/api/favorite-posts/').then((response) => {
      setFavoritePosts(response.data);
    });

    /* Typing text animation in hero section */
    let wordsAnimationTimeline = gsap.timeline({ repeat: -1 });

    words.forEach((word) => {
      let eachWordAnimationTimeline = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 5 });
      eachWordAnimationTimeline.to("#animated", { duration: 1, text: word });
      wordsAnimationTimeline.add(eachWordAnimationTimeline);
    });

    /*Changing colors of a website to the opposite*/
    let changeTheme = gsap.timeline({
      scrollTrigger: {
        trigger: ".change-theme",
        start: "top center",
        end: "20% center",
        scrub: true
      }
    });
    changeTheme.to("body", {
      "--dark-color": "#F1F1F1",
      "--white-cream-color": "#1F1F1F",
    })

    /*Parallax effect*/
    const parallaxElements = gsap.utils.toArray(".parallax");

    parallaxElements.forEach((parallaxElement) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: parallaxElement,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
          markers: false
        }, 
      }).fromTo(parallaxElement, {y: parallaxElement.dataset.start}, {y: parallaxElement.dataset.end});
    });

    /*Ripple button effect*/
    const rippleBtn = document.getElementById("ripple");
    let ripples = document.createElement("span");
    let cleartimeout;

    const buttonAnimationTimeline = gsap.timeline();
    buttonAnimationTimeline.to(ripples, {width: "500px", height: "500px", opacity: "1", duration: 0.3, delay: 0});
    buttonAnimationTimeline.reversed(true);

    rippleBtn.addEventListener("mouseover", function (e) {
      let x = e.clientX - e.target.offsetLeft;
      let y = e.clientY - e.target.offsetTop;
      ripples.style.left = x + "px";
      ripples.style.top = y + "px";
      this.appendChild(ripples);
      buttonAnimationTimeline.reversed(!buttonAnimationTimeline.reversed());
    });
    
    rippleBtn.addEventListener("mouseout", function () {
      buttonAnimationTimeline.reversed(!buttonAnimationTimeline.reversed());
    });
    
  }, []);

  return (
    <div className='d-block bg-color-light'>
        <section className='w-100 bg-color-light d-flex flex-column justify-content-between align-items-center position-relative overflow-hidden' style={{minHeight: '100dvh'}}>
            <div className='w-100 hero-section-image-container d-flex justify-content-center justify-content-md-end align-items-end p-5'>
              <a href='/contacts/' className='text-color-light font-anton fs-3 py-1 px-4 border border-2 border-white rounded-4 bg-blur ripple' id='ripple'>Contact Us</a>
            </div>

            <div className='col-11 d-flex flex-column flex-lg-row justify-content-between'>
              <div className='col-12 col-lg-7'>
                <p className='text-color-dark font-anton hero-section-big-text typing-animated-text' id='animated'></p>
              </div>

              <div className='col-12 col-md-10 col-lg-5 col-xl-5 col-xxl-4 d-flex justify-content-center align-items-center'>
                <p className='text-color-dark hero-section-small-text font-inter'>Architektura nás baví a naším hlavním cílem<br /> je hladký a včasný průběh výstavby pro naše klienty. <br /> Samozřejmostí je pro nás práce v prostředí BIM <br /> a využívaní nejnovější technologie v oblasti navrhování <br /> a projekčních prací. Poskytujeme pomoc v celém procesu výstavby od přípravy, skrze design až po stavbu na klíč.</p>              
              </div>
            </div>
        </section>

        <section className='container-fluid bg-color-light d-flex justify-content-center align-items-center vh-sm-170 vh-sm-170' style={{height: '120dvh'}}>
          <div className='col-12 h-sm-75 h-lg-65 h-75 d-flex flex-column flex-lg-row justify-content-evenly'>
            <div className='col-12 col-lg-6 h-sm-50 h-lg-65 bg-color-skin rounded-5 mb-4 d-flex flex-column flex-sm-row justify-content-around align-items-center'>
              <div className='col-11 col-sm-6 h-sm-50 h-90 rounded-5 about-us-container-image-section'></div>

              <div className='col-11 col-sm-5 h-sm-35 h-90 my-4 d-flex flex-column justify-content-between align-items-center'>
                <p className='title-big-text text-color-brown font-antonio'>Nejlepší design pro váš záměr</p>
                <div className='w-75 bg-color-brown py-4 d-flex justify-content-center align-items-center rounded-4 cursor-pointer' onClick={(e) => window.location.href = '/about/'}><h1 className='text-white font-anton m-0'>O nás</h1></div>
              </div>
            </div>

            <div className='col-12 col-lg-5 h-sm-60 h-lg-75 d-flex flex-column justify-content-between'>
              <div className='h-sm-55 h-lg-60 h-50 cursor-pointer'>
                <Link to={`/projects/${recentProject?.title}`}>
                  <div className='w-100 h-100 bg-image-configuration rounded-5 p-4 d-flex flex-column justify-content-between' style={{backgroundImage: `url(${recentProject?.main_project_image})`}}>
                    <div className='d-flex flex-row align-items-center'>
                      <div className='orange-indicator-border d-flex justify-content-center align-items-center'><div className='orange-indicator-center'></div></div>
                      <p className='text-color-light title-text-shadow font-antonio m-0 ms-1'>Nedávno přidaný projekt</p>
                    </div>

                    <div>
                      <h1 className='text-color-light font-anton title-text-shadow'>{recentProject?.title}</h1>
                    </div>
                  </div>
                </Link>
              </div>

              <div className='bg-color-brown h-sm-35 h-lg-35 h-40 mb-4 rounded-5 d-flex justify-content-center align-items-center cursor-pointer'>
                <Link to={'/projects/'}>
                  <h1 className='text-white text-center text-section font-anton'>Prohlédněte si naše práce <NextPageIcon /></h1>
                </Link>
              </div>
            </div>
          </div>
        </section>
        
        <section className='container-fluid bg-color-light d-flex justify-content-center align-items-center mb-5 change-theme position-relative' style={{height: '100dvh'}}>
          <div className='col-11 h-100 d-flex flex-wrap justify-content-center justify-content-sm-between position-relative'>
            <div className='col-12 col-sm-11 col-lg-6 h-sm-35 h-lg-50 d-flex flex-column'>
              <div className='z-2'>
                <h1 className='text-color-dark title-text-shadow hero-section-big-text font-anton parallax' data-start="150" data-end="-25">Pracujeme s AI</h1>
              </div>
              <div className='col-11 col-lg-9 ai-section-static-parallax-image rounded-5 position-relative'></div>
            </div>

            <div className='col-12 col-lg-6 h-50 h-sm-50 h-lg-50 d-flex flex-column justify-content-between align-items-end align-self-sm-center'>
              <p className='text-color-dark font-antonio text-responsive-1'>S nástupem umělé inteligence se mnohé změnilo. Tuto změnu vítáme s otevřenou náručí<br/> a kromě využívání dostupných nástrojů vyvíjíme i své vlastní<br/> a posléze dále distribuujeme jiným architektonickým<br/> a stavebním firmám.</p>

              <a href='/contacts/' className='bg-color-skin text-color-light font-anton fs-2 py-2 px-5 m-3 rounded-5'>AI nástroje</a>
            </div>
          </div>
        </section>

        <section className='container-fluid bg-color-light d-flex justify-content-center align-items-center parallax-container' style={{minHeight: '100dvh'}}>
          <div className='col-11 h-100'>
            <h1 className='text-color-dark font-anton hero-section-big-text title-text-shadow parallax' data-start="150" data-end="-25">Navštivte náš blog</h1>
            {favoritePosts.map((post, key) => (
              <div key={key} className='h-100 d-flex flex-column flex-lg-row justify-content-between'>
                <div className='col-12 col-lg-6 vh-65 vh-sm-35 vh-lg-50 mb-3 bg-image-configuration rounded-5' style={{backgroundImage: `url(${post.preview})`}}></div>
                <div className='col-12 col-lg-5 h-65 h-lg-35 d-flex flex-column justify-content-evenly justify-content-sm-between align-items-end'>
                  <div>
                    <h1 className='text-color-dark font-anton title-big-text mb-3'>{post.title}</h1>
                    <h3 className='text-color-dark font-antonio fw-light my-3'>{post.author} - {post.date}</h3>
                    <p className='text-color-dark font-inter fs-4 mt-5'>{post.description}</p>
                  </div>

                  <a href='/blog/' className='bg-color-skin text-color-light font-anton fs-2 py-2 px-5 m-3 rounded-5'>View more posts</a>
                </div>
              </div>
            ))}
          </div>
        </section>

        <Footer />
    </div>
  )
}

export default HomePage