import React, {useEffect} from 'react';
import { gsap } from 'gsap';

import { ReactComponent as PhoneIcon } from '../assets/phone_icon.svg';
import { ReactComponent as AddressIcon } from '../assets/address_icon.svg';
import { ReactComponent as InstagramIcon } from '../assets/instagram_icon.svg';
import { ReactComponent as PinterestIcon } from '../assets/pinterest_icon.svg';
import { ReactComponent as FacebookIcon } from '../assets/facebook_icon.svg';
import { ReactComponent as Logo } from '../assets/logo.svg';

const Footer = () => {

    useEffect(() => {
        const getUrl = window.location.pathname;
        let changeTextColor = gsap.timeline({});

        if (getUrl == "/") {
        changeTextColor.to("#footer", {
            "--dark-color": "#1F1F1F",
            "--white-cream-color": "#F1F1F1",
        })
        }
    });

  return (
    <div className='container-fluid bg-color-dark py-5' id='footer'>
        <div className='row d-flex justify-content-around text-white'>
            <div className='col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-end align-items-center align-items-md-start ps-4'>
                <p className='fs-6'>Tel. : +420 775 037 148</p>
                <p className='fs-6'>Wuchterlova 584/16, 16000 Praha 6</p>
            </div>
            <div className='col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-start'>
                <div className='text-center my-2 d-flex flex-row justify-content-center align-items-center'>
                    <a href='https://www.facebook.com/fbarchisoulcz'><FacebookIcon className='w-50 mx-2 cursor-pointer' /></a>
                    <a href='https://www.instagram.com/archisoulcz/'><InstagramIcon className='w-50 mx-2 cursor-pointer' /></a>
                    <a href='https://www.pinterest.com/archisoulcz/'><PinterestIcon className='w-50 mx-2 cursor-pointer' /></a>
                </div>

                <p className='text-center fs-6'><i className="bi bi-c-circle"></i> 2023 All rights reserved • Developed by VH</p>
            </div>
            <div className='col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center align-items-lg-end pe-4'>
                <div className='my-2' style={{height: "64px"}}>
                    <Logo className="my-2" fill="#f1f1f1" />
                </div>
                <p className='fs-6 text-end'>Stránka je provozována IČO: 21729603</p>
            </div>
        </div>
    </div>
  )
}

export default Footer