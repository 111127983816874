import React from 'react';
import { Link } from 'react-router-dom'
import '../static/css/posts.css';

import { ReactComponent as ArrowDown } from '../assets/arrow-down.svg';

const PostItem = ({post}) => {
  return (
    <div className='row w-100 d-flex flex-column justify-content-center align-items-center my-5 mx-0'>
      <Link to={`/blog/${post.title}`} className='col-lg-12 col-lg-10 col-xl-9 d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-stretch'>
        <div className='col-md-10 col-lg-6'>
          <img src={post.preview} className='img-fluid rounded-5' style={{maxHeight: '100vh'}} />
        </div>

        <div className='col-md-10 col-lg-5 m-0 p-0 d-flex flex-column justify-content-between'>
          <div>
            <div className='d-flex flex-row flex-lg-column justify-content-between justify-content-lg-start mb-2'>
              <p className='text-color-dark fs-1 font-anton m-0'>{post.title}</p>
              <p className='text-color-dark fs-5 font-antonio'>{post.author} - {post.date}</p>
            </div>

            <p className='text-color-dark fs-5 font-inter'>{post.description}</p>
          </div>

          <div className='w-100 text-end my-2'>
            <a href={`/blog/${post.title}`} className='bg-color-skin text-color-dark fs-3 font-anton rounded-5 py-2 px-4'>K článku <ArrowDown /></a>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PostItem