import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gsap } from 'gsap';
import axios from 'axios';

import '../static/css/posts.css';

import Footer from '../components/Footer';

const SinglePostPage = () => {

    let postTitle = useParams();
    let [post, setPost] = useState([]);

    useEffect(() => {
        axios.get(`/api/post/${postTitle['title']}/`).then((response) => {
            setPost(response.data);
        });

        let changeTextColor = gsap.timeline({});
        changeTextColor.to("#header", {
            "--dark-color": "#1F1F1F",
            "--white-cream-color": "#FFFFFF",
        });
    }, []);

  return (
    <div className='container-fluid p-0'>
        <div className='row w-100 m-0'>
            <div className='p-0 single-post-main-image' style={{backgroundImage: `url('${post?.preview}')`}}></div>
            <div className='col-12 d-flex flex-column-reverse flex-md-row justify-content-between m-0 p-0'>
                <div className='col-12 col-md-8 col-lg-7 col-xl-6 col-xxl-6 px-4 bg-white position-relative move-description-up-single-post'>
                    <h1 className='fw-semibold my-2 text-section text-dark-blue'>{post?.title}</h1>
                    <p className='fs-3 fw-medium my-4 text-dark-blue'>{post?.author} - {post?.date}</p>
                </div>

                <div className='mx-5'><p>zdroj: <a href={post?.preview_source}>{post?.preview_source}</a></p></div>
            </div>
        </div>

        <div className='row mb-5 px-2'>
            <div dangerouslySetInnerHTML={{__html: post?.content}}></div>
        </div> 

        <div className='ps-2 mb-5 fs-5 fw-semibold text-dark-blue'>
            {post?.tags?.map((tag, key) => (
                <p key={key}><i className="bi bi-dot"></i> {tag.tag_name}</p>
            ))}
        </div>

        <Footer />
    </div>
  )
}

export default SinglePostPage