import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { gsap } from 'gsap'
import axios from 'axios';

import '../static/css/singleProject.css';

import ProjectPhotoModal from '../components/ProjectPhotoModal';
import Footer from '../components/Footer';

import { ReactComponent as ArrowsDown } from '../assets/arrows_down_scroll.svg';

const SingleProjectPage = () => {

    let projectId = useParams();
    let [project, setProject] = useState([]);
    let [modalPhotoParams, setmodalPhotoParams] = useState({'photoUrl': '', 'modalActive': false});

    useEffect(() => {
        axios.get(`/api/projects/${projectId['id']}/`).then((response) => {
            setProject(response.data);
        });
        window.scrollTo(0, 0);

        let changeTextColor = gsap.timeline({});
        changeTextColor.to("#header", {
            "--dark-color": "#F1F1F1",
            "--white-cream-color": "#1F1F1F",
        });
    }, []);

    let showModal = (e) => {
        setmodalPhotoParams({'photoUrl': e.target.src, 'modalActive': true});
    }

    let hideModalFunc = () => {
        setmodalPhotoParams({'photoUrl': '', 'modalActive': false});
    }

  return (
    <div className='position-absolute top-0'>

        <ProjectPhotoModal photoUrl={modalPhotoParams['photoUrl']} modalActive={modalPhotoParams['modalActive']} hideModal={hideModalFunc} />

        <div className='container-fluid position-relative'>
            <div className='row single-project-main-image' style={{backgroundImage: `url('${project.main_project_image}')`}}>
                <div className='col-12 d-flex flex-column justify-content-evenly' style={{zIndex: '1'}}>
                    <div className='d-flex flex-column flex-lg-row justify-content-evenly' style={{paddingTop: '10vh'}}>
                        <div className='col-12 col-lg-5 col-xl-4 my-3'>
                            <h1 className='text-white text-section font-anton'>{project?.title}</h1>
                            <div>
                                <h5 className='text-white font-antonio'>fáze: {project?.phase}</h5>
                                <h5 className='text-white font-antonio'>autor: {project?.author}</h5>
                                <h5 className='text-white font-antonio'>{project?.year_of_creation}</h5>
                            </div>
                        </div>
                        <div className='col-12 col-lg-5 col-xl-5 my-3'>
                            <p className='text-white fw-normal px-2'>{project?.description}</p>
                        </div>
                    </div>
                    <div className='p-5 mb-5 cursor-pointer align-self-center' onClick={(e) => {document.getElementById('project-photos').scrollIntoView()}}>
                        <h3 className='text-white text-center fw-normal'>Prozkoumejte níže <br /> <ArrowsDown /></h3>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid position-relative mb-5' id='project-photos'>
            <div className='row w-100'>
                <div className='col-12'>
                    <img src={`${project?.main_project_image}`} className='img-fluid w-100 m-2 p-0 cursor-pointer' data-aos="fade-up" onClick={(e) => showModal(e)} />
                </div>

                <div className='col-12 col-md-6'>
                    {project?.photos?.map((projectPhoto, key) => (
                        <div key={key}>
                            {key % 2 === 0 ?<img src={`${projectPhoto?.project_image}`} className='img-fluid m-2 p-0 cursor-pointer' style={{width: '100%'}} data-aos="fade-up" onClick={(e) => showModal(e)} /> : null}
                        </div>
                    ))}
                </div>
                
                <div className='col-12 col-md-6'>
                    {project?.photos?.map((projectPhoto, key) => (
                        <div key={key}>
                            {key % 2 !== 0 ?<img src={`${projectPhoto?.project_image}`} className='img-fluid m-2 p-0 cursor-pointer' style={{width: '100%'}} data-aos="fade-up" onClick={(e) => showModal(e)} /> : null}
                        </div>
                    ))} 
                </div>
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default SingleProjectPage