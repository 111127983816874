import React from 'react'

const ProjectPhotoModal = ({photoUrl, modalActive, hideModal}) => {

  return (
    <div className={'container-fluid h-100 position-fixed top-0 start-0 bg-dark bg-opacity-75 d-flex justify-content-center align-items-center z-3 ' + (modalActive == false ? 'invisible' : 'visible')} >
        <h1 className='text-section position-absolute top-0 end-0 m-2 cursor-pointer' onClick={(e) => hideModal()}><i className="bi bi-x text-white"></i></h1>
        
        <div className='col-12 col-md-9 d-flex justify-content-center align-items-center'>   
            <img src={photoUrl} className='img-fluid' />
        </div>
    </div>
  )
}

export default ProjectPhotoModal