import React, {useEffect} from 'react';
import { gsap } from 'gsap';
import '../static/css/aboutUsPage.css';

import Slider from '../components/Slider';
import Footer from '../components/Footer'

import AboutUsBackgroundImage from '../assets/img/about_us_hero_image.jpg';
import DesignPhilosophySection from '../assets/img/about-us-design-philosophy-section.jpg';
import ThreeDesignSection from '../assets/img/about-us-3d-design-section.jpg';

const AboutUsPage = () => {
    useEffect(() => {
        //3D cards
        const cards = gsap.utils.toArray('.card')
        const topSection = document.querySelector('.card-container')

        cards.forEach((card) => {
            card.addEventListener("mousemove", (e) => {
                const cardWidth = card.offsetWidth;
                const cardHeight = card.offsetHeight;
                const cardCenterX = card.offsetLeft + cardWidth/2;
                const cardCenterY = card.offsetTop + cardHeight/2;
                const mouseX = e.clientX - cardCenterX;
                const mouseY = e.clientY - cardCenterY;

                gsap.to(card, {
                    duration: 0.5,
                    rotationY: mouseX/(cardWidth/7),
                    rotationX: -mouseY/(cardHeight/7),
                    ease: 'power2.out'
                })
            });

            card.addEventListener("mouseleave", () => {
                gsap.to(card, {
                    duration: 0.5,
                    rotationY: 0,
                    rotationX: 0,
                    ease: 'power2.out'
                })
            })
        });

        //When page is loaded
        let changeTextColor = gsap.timeline({});
        changeTextColor.to("#header", {
            "--dark-color": "#1F1F1F",
            "--white-cream-color": "#F1F1F1",
        });
    }, []);

  return (
    <div className='d-block bg-color-light'>
        <section className='container-fluid d-flex justify-content-center align-items-center move-from-header'>
            <div className='col-12 col-md-11 d-flex flex-column justify-content-around'>
                <div className='col-12 col-lg-10'>
                    <h1 className='hero-title font-anton'>Naše filozofie, způsob práce, služby</h1>
                </div>

                <div className='col-12 d-flex flex-column flex-lg-row justify-content-between'>
                    <div className='col-12 col-lg-6 col-xl-6 col-xxl-7'>
                        <img src={AboutUsBackgroundImage} className='img-fluid rounded-5' />
                    </div>

                    <div className='col-12 col-lg-5 col-xl-5 col-xxl-4 d-flex flex-column justify-content-between align-items-center'>
                        <p className='font-antonio hero-text'>Architektura nás baví a naším hlavním cílem je hladký a včasný průběh výstavby pro naše klienty. Samozřejmostí je pro nás práce v prostředí BIM a využívaní nejnovější technologie v oblasti navrhování a projekčních prací. Poskytujeme pomoc v celém procesu výstavby od přípravy, skrze design až po stavbu na klíč.</p>

                        <a href='/projects/' className='col-11 col-md-9 col-lg-12 col-xl-11 bg-color-dark text-color-light text-center font-anton fs-2 py-4 rounded-pill'>Prohlédněte si naše práce</a>
                    </div>
                </div>
            </div>
        </section>

        <div className='container-fluid my-5'>
            <div className='row d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around'>
                <div className='col-12 col-md-10 col-lg-5 col-xl-4'>
                    <h1 className='hero-title font-anton'>O nás</h1>
                    <div>
                        <div className='col-12'>
                            <p className='fs-4 font-antonio'>Jsme mladí, motivováni a v roku 2023 jsme si řekli že se vydáme vlastním směrem. Spojením slovenského architektonického talentu a rumunsko-ukrajinského talentu z oblasti stavebnictví vzniklo duo Archisoul zabývající se navrhováním a uskutečňováním vize našich klientů. K práci přistupujeme s vášní a vážností. Nerespektujeme zastaralé procesy v navrhování a projekování a hledáme způsoby jak urychlit proces výstavby za pomoci veškerých dostupných metod.</p>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6'>
                    <h1 className='hero-title font-anton'>Náš tým</h1>
                </div>
            </div>
        </div>

        <section className='container-fluid my-5'>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-11'>
                    <h1 className='hero-title font-anton'>Služby</h1>
                </div>

                <div className='w-100 my-5 d-flex flex-lg-row flex-wrap justify-content-around card-container'>
                        <div className='col-md-5 col-xl-4 col-xxl-3 bg-color-skin py-3 px-2 my-4 mx-xl-3 rounded-4 d-flex flex-column justify-content-start align-items-center card'>
                            <h1 className='font-anton text-color-dark'>Design architektury</h1>

                            <div className='col-12 col-lg-11 col-xxl-10'>
                                <p className='fs-6 font-inter'>Je dialogem mezi investorem a architektem, plnením snů a velkou investicí. Určuje průběh investorského záměru a dává mu reálnou tvář. Estetika, uživatelské pohodlí a udržitelnost jsou pro nás vážná témata a jsme rádi když můžeme naším klientům přinést to nejlepší možné řešení. Neřídíme se pouze trendy a hledáme způsoby jak našim klientům přinést to co potřebují a ocení. Všechno začíná stanovením si cílů a pochopením budoucího fungování investičního záměru.</p>
                            </div>
                        </div>

                        <div className='col-md-5 col-xl-4 col-xxl-3 bg-color-skin py-3 px-2 my-4 mx-xl-3 rounded-4 d-flex flex-column justify-content-start align-items-center card'>
                            <h1 className='font-anton text-color-dark'>Design osvětlení</h1>

                            <div className='col-12 col-lg-11 col-xxl-10'>
                                <p className='fs-6 font-inter'>Neboli lighting design je navrhování umělého osvětlení do interiérů a exteriérů. Chcete-li dostat vaší stavbu na další úroveň a přinést do prostorů nadstandardní atmosféru a uživatelskou pohodu je dobré na osvětlení myslet už při prvních fázích návrhu. Pro nás je světlo vášní kterou rádi dle možností našich klientů promyslíme do detailu.  Spolupracujeme s Evropskými dodavateli svítidel a máme zkušenosti s osvětlováním rodinných domů, komerčních interiérů i developerských megaprojektů z blízkého východu.</p>
                            </div>
                        </div>

                        <div className='col-md-5 col-xl-4 col-xxl-3 bg-color-skin py-3 px-2 my-4 mx-xl-3 rounded-4 d-flex flex-column justify-content-start align-items-center card'>
                            <h1 className='font-anton text-color-dark'>Inženýring</h1>

                            <div className='col-12 col-lg-11 col-xxl-10'>
                                <p className='fs-6 font-inter'>Neboli vybavování nezbytných územních/stavebních povolení děláme rádi a s nutnou precizností. Máme praktické skušenosti s povolovacím procesem výstavby v ČR a na Slovensku které využíváme pro včasné započetí výstavby našich klientů a legalizaci staveb. Každý den kdy investice vázne na získání potřebných povolení znamená nerealizovanou ztrátu pro investora a nám dělá radost když je toto období co nejkratší v porovnání s ideálním teoretickým stavem.</p>
                            </div>
                        </div>
                    </div>
            </div>
        </section>

        <Footer />
    </div>
  )
}

export default AboutUsPage