import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import React from 'react';
import './App.css';
import AOS from 'aos';

/* Importing Components */
import Header from './components/Header';
import CollectCookiesForm from './components/CookiesForm';

/* Importing Different Website Pages */
import AboutUsPage from './pages/AboutUsPage';
import ContactsPage from './pages/ContactsPage';
import HomePage from './pages/HomePage';
import PostsPage from './pages/PostsPage';
import ProjectsPage from './pages/ProjectsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import SinglePostPage from './pages/SinglePostPage';
import SingleProjectPage from './pages/SingleProjectPage';

function App() {

  AOS.init();

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path='/' Component={HomePage} />
          <Route exact path='blog/' Component={PostsPage} />
          <Route path='blog/:title' Component={SinglePostPage} />
          <Route exact path='about/' Component={AboutUsPage} />
          <Route exact path='contacts/' Component={ContactsPage} />
          <Route exact path='projects/' Component={ProjectsPage} />
          <Route path='projects/:id' Component={SingleProjectPage} />
          <Route path='privacy-and-policy/' Component={PrivacyPolicyPage} />
        </Routes>
        <CollectCookiesForm />
      </div>
    </Router>
  );
}

export default App;
