import React, {useEffect, useState} from 'react';
import { gsap } from 'gsap';
import axios from 'axios';

import ProjectItem from '../components/ProjectItem';
import Footer from '../components/Footer';

const ProjectsPage = () => {

    let [projects, setProjects] = useState([])

    useEffect(() => {
        axios.get('/api/projects/').then((response) => {
            setProjects(response.data);
        });

        let changeTextColor = gsap.timeline({});
        changeTextColor.to("#header", {
            "--dark-color": "#1F1F1F",
            "--white-cream-color": "#F1F1F1",
        });
    }, []);

  return (
    <div className='container-fluid m-0 p-0' style={{marginTop: '10vh'}}>
        <div className='row my-5 mx-5 pt-5 d-flex flex-wrap justify-content-around'>
            {projects.map((project, index) => (
                <ProjectItem key={index} project={project} />
            ))}
        </div>

        <Footer />
    </div>
  )
}

export default ProjectsPage