import React, { useEffect, useState } from 'react';
import axios from 'axios';

import PostItem from '../components/PostItem';
import Footer from '../components/Footer';

const PostsPage = () => {

    let [posts, setPosts] = useState([])

    useEffect(() => {
        axios.get('/api/posts/').then((response) => {
            setPosts(response.data);
        });
    }, []);

  return (
    <div className='container-fluid m-0 px-0 bg-color-light move-from-header'>
        {posts.map((post, key) => (
            <PostItem key={key} post={post} />
        ))}

        <Footer />
    </div>
  )
}

export default PostsPage