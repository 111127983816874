import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className='container move-from-header'>
        <div className='row'>
            <p className='font-inter fs-2 fw-bold'>Zásady práce s vašimi údaji</p>
            <p className='font-inter fs-6'>Jsme volné sdružení dvou fyzických osob podnikajících dle živnostenského zákona: Tomáš Haulík IČO: 11720824 a Nutsu Vlad IČO: 17473136</p>
            <p className='font-inter fs-6'>Společně jsme provozovateli webové stránky https://archisoul.cz/</p>
            <p className='font-inter fs-6'>Pro poskytování našich služeb a provoz našich webových stránek zpracováváme některé osobní údaje. Zpracování osobních údajů v České republice upravuje zejména nařízení Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) („GDPR“)</p>
            <div className='mt-2'>
                <p className='font-inter fs-4 fw-bold mb-0'>I. Zpracování osobních údajů</p>
                <p className='font-inter fs-6 mb-1'>A. Zpracování osobních údajů v případě použití kontaktního formuláře</p>
                <p className='font-inter fs-6 mb-1'>Pokud poptáváte naše služby skrze kontaktní  formulář, budeme pracovat s vašimi kontaktními údaji. Jsou to Vaše jméno a email. Konáme tak za účelem kontaktování Vás pro další domluvu ohledně našich služeb. Jedná se o zpracování na základě čl. 6 odst. 1 písm.</p>
                <p className='font-inter fs-6'>b) GDPR - jednání o smlouvě, resp. provedení opatření před uzavřením smlouvy na vaši žádost. Pokud nenavážeme další spolupráci, vaše data budeme zpracovávat nejdéle 14 dnů od naší poslední komunikace. V případě navázání spolupráce budou používány za účelem komunikace po dobu trvání spolupráce a nebudou bez vašeho souhlasu sdíleny třetím stranám. Po ukončení spolupráce budou vaše data zpracovány nejdéle 14 dnů od naší poslední komunikace.</p>
                <p className='font-inter fs-6 mb-1'>B. Newslettery (obchodní sdělení)</p>
                <p className='font-inter fs-6'>Pokud jste nakupující zákazník a nezakázali jste nám to při nákupu, použijeme vaši e-mailovou adresu pro rozesílku našich novinek na základe ust. § 7 odst. 3 zákona č. 480/2004 Sb., o některých službách informační společnosti. Pokud nedojde k odhlášení rozesílka bude pokračovat nejdéle 10 let od vašeho posledního nákupu. Z rozesílky se můžete kdykoliv odhlásit prostřednictvím e-mailu nebo nás kontaktujte na e-mailu: <a href="mailto:info@archisoul.cz">info@archisoul.cz</a></p>
            </div>

            <div className='mt-2'>
                <p className='font-inter fs-4 fw-bold mb-0'>II. Přístup k Vašim datům</p>
                <p className='font-inter fs-6 mb-0'>Provozovatelé webu:</p>
                <p className='font-inter fs-6 mb-0'>Tomáš Haulík IČO: 11720824</p>
                <p className='font-inter fs-6 mb-3'>Nutsu Vlad IČO: 17473136</p>

                <p className='font-inter fs-6'>Osobní údaje zpracováváme pouze na území Evropské unie.</p>
            </div>

            <div className='mt-2'>
                <p className='font-inter fs-4 fw-bold mb-0'>III. Co byste dál měli vědět</p>
                <p className='font-inter fs-6 mb-0'>V našem sdružení nemáme jmenovaného pověřence pro ochranu osobních údajů.</p>
                <p className='font-inter fs-6 mb-0'>Nedochází k rozhodování na základě automatického zpracování či profilování.</p>
                <p className='font-inter fs-6'>Pokud byste měli k osobním údajům otázky, kontaktujte nás na e-mailové adrese info@archisoul.cz nebo zavolejte na tel. č. +421 944 145 270</p>
            </div>

            <div className='mt-2'>
                <p className='font-inter fs-4 fw-bold mb-0'>IV. Používání souborů cookies</p>
                <p className='font-inter fs-6 mb-1'>Cookies jsou textové soubory obsahující malé množství informací, které se při návštěvě našich webových stránek stahují do Vašeho zařízení. Soubory cookies se následně při každé další návštěvě odesílají zpět na webovou stránku nebo jinou webovou stránku, která je rozpozná.</p>
                <p className='font-inter fs-6'>Soubory cookies plní různé úlohy, například umožňují efektivní navigaci mezi webovými stránkami, zapamatování si Vašich preferencí a celkově zlepšují zkušenost uživatele. Dokážou také zajistit, aby reklamy zobrazované on-line byly lépe přizpůsobené Vaší osobě a Vašim zájmům.</p>
                <p className='font-inter fs-6 mb-0'>Na webových stránkách používáme následující cookies:</p>
                <p className='font-inter fs-6 mb-1'>Nezbytné cookies: jsou zapotřebí k provozu webových stránek, umožňují například přihlásit se do zabezpečených částí stránek a další základní funkčnosti stránek. Tato kategorie cookies se nedá zakázat.</p>
                <p className='font-inter fs-6 mb-1'>Analytické/statistické cookies: umožňují nám například rozpoznat a zjistit počet návštěvníků a sledovat, jak naši návštěvníci používají webové stránky. Pomáhají nám zlepšovat způsob, jakým stránky fungují, zejména ulehčují uživatelům najít hledané výrazy. Tyto soubory spouštíme pouze s Vaším předchozím souhlasem.</p>
                <p className='font-inter fs-6 mb-1'>Reklamní cookies: používají se ke sledování preferencí a umožňují zobrazit reklamu a další obsah, které nejlépe odpovídají vašemu zájmu a online chování. Tyto soubory spouštíme pouze s Vaším předchozím souhlasem.</p>
                <p className='font-inter fs-6 mb-1'>Upozorňujeme, že třetí strany (včetně např. poskytovatelů externích služeb) mohou rovněž používat cookies a/nebo přistupovat k údajům shromažďováním cookies na webových stránkách.</p>
                <p className='font-inter fs-6 mb-1'>Další informace o cookies a jejich aktuální seznam naleznete prostřednictvím jednotlivých internetových prohlížečů, nejčastěji v položce Nástroje pro vývojáře.</p>
                <p className='font-inter fs-6'>Souhlas může být vyjádřen prostřednictvím zaškrtávacího políčka obsaženého v tzv. cookie liště. Soubory cookies můžete i následně v nastavení svého internetového prohlížeče odmítnout, nebo si nastavit užívání jen některých.</p>
                
                <p className='font-inter fs-6 mb-1'>Další informace o správě cookies v jednotlivých prohlížečích naleznete na následujících odkazech:</p>
                <p className='font-inter fs-6 mb-1'>Internet Explorer - <a href='https://support.microsoft.com/cs-cz/help/17442/windows-internet-explorer-delete-manage-cookies'>https://support.microsoft.com/cs-cz/help/17442/windows-internet-explorer-delete-manage-cookies</a></p>
                <p className='font-inter fs-6 mb-1'>Google Chrome - <a href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=cs'>https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=cs</a></p>
                <p className='font-inter fs-6 mb-1'>Firefox - <a href='https://support.mozilla.org/cs/kb/povoleni-zakazani-cookies'>https://support.mozilla.org/cs/kb/povoleni-zakazani-cookies</a></p>
                <p className='font-inter fs-6 mb-1'>Safari - <a href='https://support.apple.com/cs-cz/guide/safari/sfri11471/mac'>https://support.apple.com/cs-cz/guide/safari/sfri11471/mac</a></p>
                <p className='font-inter fs-6 mb-1'>Opera - <a href='https://help.opera.com/cs/latest/security-and-privacy/'>https://help.opera.com/cs/latest/security-and-privacy/</a></p>
                <p className='font-inter fs-6'>Microsoft Edge - <a href='https://docs.microsoft.com/cs-cz/sccm/compliance/deploy-use/browser-profiles'>https://docs.microsoft.com/cs-cz/sccm/compliance/deploy-use/browser-profiles</a></p>
            </div>

            <div className='mt-2'>
                <p className='font-inter fs-4 fw-bold mb-0'>V. Vaše práva v souvislosti se zpracováním osobních údajů</p>
                <p className='font-inter fs-6 mb-1'>Nařízení GDPR Vám dává mimo jiné právo obrátit se na nás a chtít informace, jaké vaše osobní údaje zpracováváme, vyžádat si u nás přístup k těmto údajům a nechat je aktualizovat nebo opravit, popřípadě požadovat omezení zpracování, můžete požadovat kopii zpracovávaných osobních údajů, požadovat po nás v určitých situacích výmaz osobních údajů a v určitých případech máte právo na jejich přenositelnost. Proti zpracování na základě oprávněného zájmu lze vznést námitku na uvedených kontaktních kanálech.</p>
                <p className='font-inter fs-6 mb-0'>Pokud si myslíte, že s daty nakládáme nesprávně, máte právo podat stížnost u Úřadu pro ochranu osobních údajů, případně se se svými nároky obrátit na soud.</p>
                <p className='font-inter fs-6'>Tyto podmínky jsou účinné od 1. 1. 2024</p>
            </div>
        </div>
    </div>
  )
}

export default PrivacyPolicyPage