import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import '../static/css/header.css';

import Menu from './Menu';

import { ReactComponent as BurgerMenu } from '../assets/menu.svg';
import { ReactComponent as CloseMenu } from '../assets/closeMenu.svg';
import { ReactComponent as Logo } from '../assets/logo.svg';

const Header = () => {
  gsap.registerPlugin(ScrollTrigger);

  const getUrl = window.location.pathname;

  let [menuActive, setMenuActive] = useState(false);

  /* Show menu functionality */
  let showMenu = async () => {
    let changeTextColor = gsap.timeline({});

    let menuNavigation = document.getElementById('menu-navigation');
    let companyName = document.getElementById('company-logo');
    if (menuActive === false){
      setMenuActive(true);
      menuNavigation?.classList.add('show-menu');
      menuNavigation?.classList.remove('hide-menu');
      changeTextColor.to("#header", {
        "--dark-color": "#F1F1F1",
        "--white-cream-color": "#1F1F1F",
      })

    } else {
      setMenuActive(false);
      menuNavigation?.classList.remove('show-menu');
      menuNavigation?.classList.add('hide-menu');

      if (getUrl == "/") {
        changeTextColor.to("#header", {
          "--dark-color": "#F1F1F1",
          "--white-cream-color": "#1F1F1F",
        })
      } else {
        changeTextColor.to("#header", {
          "--dark-color": "#1F1F1F",
          "--white-cream-color": "#F1F1F1",
        })
      }

    }
  }

  useEffect(() => {
    let changeTextColor = gsap.timeline({});

    if (getUrl === "/") {
      changeTextColor.to("#header", {
        "--dark-color": "#F1F1F1",
        "--white-cream-color": "#1F1F1F",
      });
    }

    window.addEventListener('scroll', (e) => {
      if (window.scrollY > 150) {
        document.getElementById("header").classList.add("bg-color-light");
      } else {
        document.getElementById("header").classList.remove("bg-color-light");
      }
    })

    const showAnim = gsap.from('#header', { 
      yPercent: -100,
      paused: true,
      duration: 0.2,
    }).progress(1);
    
    ScrollTrigger.create({
      start: "top top",
      end: 99999,
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      }
    });
  });


  return (
    <header className='position-relative' style={{zIndex: '3'}}>
      <div className='container-fluid position-fixed top-0 d-flex flex-row justify-content-around align-items-center py-md-3' id='header' style={{transition: '.3s ease-in-out'}}>
          <div className='col-6 col-md-5 col-lg-3 col-xl-2 position-relative' style={{zIndex: '4'}} data-aos="fade-down" data-aos-delay="200">
            <div className='w-100 d-flex justify-content-center align-items-center m-0'>
              <a href='/' className='w-100 text-center'><Logo fill="var(--dark-color)" /></a>
            </div>
          </div>
          
          <div className='col-6 d-flex flex-row justify-content-around align-items-center lg-visible'>
            <a href='/about/' className='text-color-dark font-inter fs-5 px-3 py-2 menu-button'>O nás</a>
            <a href='/projects/' className='text-color-dark font-inter fs-5 px-3 py-2 menu-button'>Projekty</a>
            <a href='/contacts/' className='text-color-dark font-inter fs-5 px-3 py-2 menu-button'>AI nástroje</a>
            <a href='/contacts/' className='text-color-dark font-inter fs-5 px-3 py-2 menu-button'>Kontakt</a>
            <a href='/blog/' className='text-color-dark font-inter fs-5 px-3 py-2 menu-button'>Blog</a>
          </div>

          <div className='col-2 mx-5 d-flex justify-content-center align-items-center lg-hidden' style={{zIndex: '4'}} data-aos="fade-down" data-aos-delay="200">
            <a className="text-center" onClick={showMenu}>{menuActive === false ? <BurgerMenu fill={getUrl == "/" ? "#F1F1F1" : "#1F1F1F"} /> : <CloseMenu />}</a>
          </div>
          <Menu />
      </div>
    </header>
  )
}

export default Header