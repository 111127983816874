import React from 'react'

const Menu = () => {
  return (
    <nav className='w-100 bg-dark d-flex justify-content-center align-items-md-center position-fixed top-0 hide-menu' style={{paddingTop: '20vh', height: "100dvh"}} id='menu-navigation'>
        <ul className='col-12 col-md-10 h-75 ms-5 d-flex flex-column justify-content-around'>
            <li>
                <a href='/about/' className='text-white font-antonio'>O nás</a>
            </li>

            <li>
                <a href='/projects/' className='text-white font-antonio'>Projekty</a>
            </li>
            
            <li>
                <a href='/contacts/' className='text-white font-antonio'>AI nástroje</a>
            </li>

            <li>
                <a href='/contacts/' className='text-white font-antonio'>Kontakt</a>
            </li>

            <li>
                <a href='/blog/' className='text-white font-antonio'>Blog</a>
            </li>
        </ul>
    </nav>
  )
}

export default Menu