import React from 'react';
import { Link } from 'react-router-dom';

import second from '../static/css/projects.css';

const ProjectItem = ({project}) => {
  
    return (
    <div className='col-11 col-md-6 col-lg-6 col-xl-4 mt-5 position-relative project-container cursor-pointer' data-aos="fade-up" data-aos-offset="100">
        <Link to={`/projects/${project.title}`}>
            <img src={`${project.main_project_image}`} className='w-100 project-image' />
            <div className='opacity-0 project-info col-11'>
                <h1 className='text-white text-ellipsis ms-2'>{project.title}</h1>
            </div>
        </Link>
    </div>
  )
}

export default ProjectItem